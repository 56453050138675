import configuration from '../../lib/configuration';
import template from './phLogin.html';
import './phLogin.less';

export default [
  function () {
    return {
      template,
      restrict: 'E',
      link(scope) {
        scope.currentYear = new Date().getFullYear();
        scope.currentVersion = configuration.get('releaseVersion') || '';
      }
    };
  }
];
