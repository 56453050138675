/* eslint-disable no-underscore-dangle */
const config = {
  env: 'local',
  apiDomain: 'http://localhost:8081',
  providerPortalUrl: 'http://localhost:9000',
  patientPortalDomain: 'localhost:3000'
};

if (window.__serverVars) {
  Object.assign(config, window.__serverVars);
}

export default {
  get(key) {
    return config[key];
  },

  setAll(anObject){
    Object.assign(config, anObject);
  }
};
