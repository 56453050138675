import template from './passwordReset.html';
import './passwordReset.less';

export default [
  '$window',
  '$timeout',
  'LoginService',
  function ($window, $timeout, LoginService) {
    return {
      template,
      restrict: 'E',
      link(scope) {
        const emailPattern = LoginService.getEmailPattern();

        scope.newPassword = '';
        scope.newPasswordDuplicate = '';

        scope.email = '';
        scope.errorMessage = '';

        scope.state = -1;

        scope.verifyingEmail = false;
        scope.settingPassword = false;

        scope.newPasswordValid = false;
        scope.passwordRequirementsUnmet = false;

        scope.passwordsMatch = false;
        scope.passwordsDoNotMatch = false;

        scope.clearError = function () {
          scope.passwordRequirementsUnmet = false;
          scope.passwordsDoNotMatch = false;
          scope.errorMessage = '';
        };

        scope.sendResetPasswordEmail = function () {
          if (scope.email.trim() === '') {
            scope.errorMessage = 'PLEASE-ENTER-EMAIL';
            return;
          }
          if (!emailPattern.test(scope.email)) {
            scope.errorMessage = 'NOT-VALID-EMAIL';
            return;
          }
          scope.verifyingEmail = true;

          LoginService.sendResetPasswordEmail({ email: scope.email })
            .then(function () {
              scope.state = LoginService.getAppState();
            })
            .catch(function (error) {
              scope.errorMessage = error;
              scope.verifyingEmail = false;
            });
        };

        scope.exitForgotPasswordFlow = function () {
          scope.$destroy();
          $window.location  = '/';
        };

        scope.setNewPassword = function () {
          if (
            scope.upperCharError ||
            scope.lowerCharError ||
            scope.numberError ||
            scope.lengthError
          ) {
            scope.errorMessage = 'PASSWORD-REQUIREMENTS-NOT-MET';
            scope.passwordRequirementsUnmet = true;
            return;
          }

          if (scope.newPassword !== scope.newPasswordDuplicate) {
            scope.errorMessage = 'PASSWORDS-DO-NOT-MATCH';
            scope.passwordsDoNotMatch = true;
            return;
          }

          scope.settingPassword = true;

          LoginService.setNewPassword({ password: scope.newPassword })
            .then(function () {
              scope.state = LoginService.getAppState();
              // this kills the webware session
              return LoginService.signOut3x();
            })
            .then(function () {
              $timeout(function () {
                // this redirects synchronously
                // if we want "Ok" UI, we need to delay it
                LoginService.signOut4x();
              }, 2000);
            })
            .catch(function (error) {
              scope.errorMessage = error;
              scope.settingPassword = false;
            });
        };

        // INIITIALIZE HERE
        $timeout(function () {
          scope.state = LoginService.getAppState();
        }, 100);
      }
    };
  }
];
