import Keycloak from 'keycloak-js';
import configuration from '../lib/configuration';

export default [
  '$http',
  '$q',
  '$location',
  function ($http, $q, $location) {
    const API_3X_VERSION = '3.3.0';

    const auth = new Keycloak({
      url: configuration.get('authUrl'),
      realm: 'ph',
      clientId: configuration.get('authClient')
    });

    // auth needs to be initialized before we can call logout.
    // We also need to be able to chain off the init in sign in
    const authPromise = auth.init({
      checkLoginIframe: false,
      promiseType: 'native'
    });

    function getRequestDefaults() {
      return {
        timeout: 10000,
        withCredentials: true,
        headers: {
          'x-ph-api-version': API_3X_VERSION
        }
      };
    }

    const urlPath = $location.path() || '/';

    // reset password state vars
    const enterForgotPasswordFlow = urlPath.indexOf('/set-password') >= 0;
    let resetEmailConfirmed = false;
    let resetPasswordKeyConfirmed = false;
    let newPasswordConfirmed = false;

    const searchParams = $location.search();

    const resetPasswordUid = searchParams.uid || '';
    const resetPasswordResetKey = searchParams.reset_key || '';
    const setPasswordVerifyKey = searchParams.verify_key || '';

    if (
      enterForgotPasswordFlow &&
      resetPasswordUid &&
      (resetPasswordResetKey || setPasswordVerifyKey)
    ) {
      resetEmailConfirmed = true;
      resetPasswordKeyConfirmed = true;
    }

    const LoginService = {
      getEmailPattern() {
        // eslint-disable-next-line no-control-regex, no-useless-escape
        return /^((([a-zA-Z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-zA-Z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-zA-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-zA-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-zA-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/;
      },
      getAppState() {
        let state = 0;

        if (enterForgotPasswordFlow) {
          state = 1;
          if (resetEmailConfirmed) {
            state = 2;
            if (resetPasswordKeyConfirmed) {
              state = 3;
              if (newPasswordConfirmed) {
                state = 4;
              }
            }
          }
        }

        return state;
      },
      sendResetPasswordEmail(options) {
        const request = getRequestDefaults();

        request.method = 'POST';
        request.url = `${configuration.get('apiDomain')}/api/users/lostPassword`;
        request.data = {
          email: options.email
        };

        return $http(request)
          .then(function (response) {
            resetEmailConfirmed = true;
            return response;
          })
          .catch(function (response) {
            let errorMessage = 'SERVER-ERROR';

            // if we caught the error earlier in the promise chaing and passed up a message, use that
            if (typeof response === 'string') {
              errorMessage = response;
            }
            // otherwise if the response has a status code between 400 and 500, something
            // went wrong with validating the user's credentials
            else if (response.status >= 400 && response.status < 500) {
              // This should show a less definitive message,
              // eg. "If we know you you'll receive an email..."
              errorMessage = 'NOT-A-PROPELLER-USER';
            }

            return $q.reject(errorMessage);
          });
      },
      setNewPassword(options) {
        const request = getRequestDefaults();

        if (!resetPasswordUid) {
          return $q.reject('SERVER-ERROR');
        }

        request.method = 'PUT';
        request.url = `${configuration.get('apiDomain')}/api/users/${resetPasswordUid}/password`;

        request.data = {
          password: options.password
        };

        if (resetPasswordResetKey) {
          request.data.resetKey = resetPasswordResetKey;
        } else if (setPasswordVerifyKey) {
          request.data.verifyKey = setPasswordVerifyKey;
        }

        return $http(request)
          .then(function (response) {
            newPasswordConfirmed = true;
            return response;
          })
          .catch(function (response) {
            let errorMessage = 'SERVER-ERROR';
            const errorObj =
              response && response.data && response.data.elements && response.data.elements[0];

            // if we caught the error earlier in the promise chaing and passed up a message, use that
            if (typeof response === 'string') {
              errorMessage = response;
            } else if (errorObj && typeof errorObj.description === 'string') {
              errorMessage = errorObj.description;
            }
            // otherwise if the response has a status code between 400 and 500, something
            // went wrong with validating the user's credentials
            else if (response.status >= 400 && response.status < 500) {
              errorMessage = 'COULD-NOT-UPDATE-PASSWORD';
            }

            return $q.reject(errorMessage);
          });
      },
      signIn() {
        return authPromise.then((authenticated) => {
          if (!authenticated) {
            // redirects to keycloak
            auth.login();
            return null;
          }

          return auth;
        });
      },
      signOut3x() {
        const request = getRequestDefaults();

        request.method = 'POST';
        request.url = `${configuration.get('apiDomain')}/api/users/signOut`;

        return $http(request).catch(() => {});
      },
      signOut4x() {
        const url = $location.absUrl();
        const redirectUri = url.slice(0, url.indexOf('?')).replace('set-password', '');

        auth.logout({ redirectUri });
      }
    };

    return LoginService;
  }
];
