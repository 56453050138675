export const DEFAULT_LOCALE = 'en-US';

const LOCALES = [
  ['ca-ES', 'Català'],
  ['cs-CZ', 'čeština'],
  ['da-DK', 'Dansk'],
  ['de-CH', 'Deutsch (CH)'],
  ['de-AT', 'Deutsch (AT)'],
  ['de-DE', 'Deutsch (DE)'],
  ['el-GR', 'Ελληνικά'],
  ['en-AU', 'English (AU)'],
  ['en-CA', 'English (CA)'],
  ['en-GB', 'English (GB)'],
  ['en-IE', 'English (IE)'],
  ['en-US', 'English (US)'],
  ['es-AR', 'Español (AR)'],
  ['es-ES', 'Español (ES)'],
  ['es-MX', 'Español (MX)'],
  ['es-US', 'Español (US)'],
  ['fi-FI', 'Suomi (FI)'],
  ['fr-BE', 'Français (BE)'],
  ['fr-CA', 'Français (CA)'],
  ['fr-CH', 'Français (CH)'],
  ['fr-FR', 'Français (FR)'],
  ['it-CH', 'Italiano (CH)'],
  ['it-IT', 'Italiano (IT)'],
  ['ja-JP', '日本語'],
  ['ko-KR', '한국어'],
  ['nb-NO', 'norsk bokmål'],
  ['nl-BE', 'Nederlands (BE)'],
  ['nl-NL', 'Nederlands (NL)'],
  ['pt-PT', 'Português (PT)'],
  ['ru-RU', 'Pусский'],
  ['sv-FI', 'Svenska(FI)'],
  ['sv-SE', 'Svenska(SE)']
];

// Firefox does not have es-us, only es (but it does have other countries),
// so we cast es to es-us. On the system level, Mac uses the code es-419
// for US spanish (which is the code for 'Spanish as used in Latin America
// and the Caribbean'). iOS uses es-xl (and seems to use it for all Latin America regions...)
export const SUBSTITUTIONS = {
  ca: 'ca-ES',
  de: 'de-DE',
  en: 'en-US',
  es: 'es-ES',
  'es-419': 'es-US',
  'es-xl': 'es-US',
  fr: 'fr-FR',
  it: 'it-IT',
  ko: 'ko-KO',
  nl: 'nl-NL',
  pt: 'pt-PT',
  ru: 'ru-RU'
};

const LOCALE_FALLBACKS = {
  'de-AT': 'de-DE',
  'de-CH': 'de-DE',
  'en-AU': 'en-GB',
  'en-CA': 'en-US',
  'en-IE': 'en-GB',
  'fr-CH': 'fr-FR',
  'fr-BE': 'fr-FR',
  'it-CH': 'it-IT',
  'nl-BE': 'nl-NL',
  'sv-FI': 'sv-SE'
};

export function fallbacksFor(lng) {
  const fallback = LOCALE_FALLBACKS[lng];
  const result = fallback ? [fallback, DEFAULT_LOCALE] : DEFAULT_LOCALE;

  return result;
}

export const allowedLanguages = LOCALES.map(([key]) => key);

export function determinePreferredLanguage(window) {
  const userLang =
    window.navigator.languages ||
    window.navigator.language ||
    window.navigator.browserLanguage ||
    window.navigator.systemLanguage ||
    window.navigator.userLanguage;

  if (!userLang) {
    return DEFAULT_LOCALE;
  }

  const userLangs = []
    .concat(userLang)
    .map((lang) => {
      lang = lang.replace(/_/g, '-');
      return allowedLanguages.indexOf(lang) > -1 ? lang : SUBSTITUTIONS[lang];
    })
    .filter(Boolean);

  return userLangs.shift() || DEFAULT_LOCALE;
}
