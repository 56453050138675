/* eslint-disable import/prefer-default-export */
import configuration from './configuration';
import { isClinician } from './roles';

// I don't think we sould need/have this, but preserving prior functionality
function patientAppDomainFor(env) {
  switch (env) {
    case 'test':
      return 'propellerhealth.io';

    case 'dev':
      return 'propellerhealth.biz';

    case 'demo':
      return 'propellerhealth.onl';

    case 'production':
      return 'propellerhealth.com';

    case 'local':
      return 'localhost:9000';

    default:
      return 'propellerhealth.com';
  }
}

// I don't think we sould need/have this, but preserving prior functionality
function patientAppURL(env) {
  let patientDomain = configuration.get('patientPortalDomain') || patientAppDomainFor(env);

  // this feels like cludge that should be simplified, but drawn from prior implementation
  if (patientDomain === 'localhost') {
    patientDomain += `:${configuration.get('patientPortalPort')}`;
  }

  const protocol = env === 'local' ? 'http' : 'https';

  return `${protocol}://patient.${patientDomain}`;
}

function providerAppURLFor(env) {
  switch (env) {
    case 'test':
      return 'https://provider.propellerhealth.io';

    case 'dev':
      return 'https://provider.propellerhealth.biz';

    case 'demo':
      return 'https://provider.propellerhealth.onl';

    case 'production':
      return 'https://provider.propellerhealth.com';

    case 'local':
      return 'http://localhost:8081';

    default:
      return 'https://provider.propellerhealth.com';
  }
}

// userAuth is a keycloak object that contains the tokens, etc, not a PH user object
export function appUrlForUser(userAuth) {
  const env = configuration.get('env');
  const userApiDomain = userAuth.idTokenParsed.ph_api_url;

  let redirectURL = isClinician(userAuth)
    ? configuration.get('providerPortalUrl') || providerAppURLFor(env)
    : patientAppURL(env);

  // we should always have this if the client is set up appropriatly on keycloak,
  // but add a guard just in case.
  if (userApiDomain) {
    /**
     * if redirectURL contains "propellerhealth.TLD", we want to make sure that's the TLD in the userApiDomain.
     * Really, we could probably just so something like this:
     * ```
     * const subdomain = isClinician(userAuth) ? 'provider' : 'patient';
     * return `https://${subdomain}/${userApiDomain}`;
     * ```
     * But we want to allow a localhost URL, and perhaps for the platform to change the core URL in the future.
     *
     * We could also apply this top level domain redirection to the individual apps.
     */

    const propellerApiRx = /propellerhealth\.\w+/i;

    // .match returns either null or an array with matches. We want the first.
    const domainForUser = userApiDomain.match(propellerApiRx)?.shift();
    if (domainForUser) {
      // if redirectURL also contains a rx match, replace it. localhost will not match.
      redirectURL = redirectURL.replace(propellerApiRx, domainForUser);
    }
  }

  return redirectURL;
}
