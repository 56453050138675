// This password checker was sourced from the same function we use on the server in /routes/api/users.js

export default function passwordRules(value) {
  const password = value || '';

  const checks = {
    allValid: false,
    upper: false,
    lower: false,
    numberCheck: false,
    lengthCheck: false
  };

  // length check
  if (password.length >= 8) {
    checks.lengthCheck = true;
  }

  // number check
  if (password.match(/\d/g)) {
    checks.numberCheck = true;
  }

  // upper & lower character checks
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < password.length; i++) {
    const c = password.charAt(i);
    // if both are true it means we have a non-letter
    checks.upper = checks.upper || (c === c.toUpperCase() && c !== c.toLowerCase());
    // if both are true it means we have a non-letter
    checks.lower = checks.lower || (c === c.toLowerCase() && c !== c.toUpperCase());
  }

  if (checks.upper && checks.lower && checks.lengthCheck && checks.numberCheck) {
    checks.allValid = true;
  }

  return checks;
}
