import passwordRules from '../lib/passwordRules';

export default function passwordCheck () {
  return {
    restrict: 'A',
    require: 'ngModel',
    link(scope, element, attrs, ngModel) {
      const elementName = element[0].name;

      function passwordChecks(value) {
        const validProperties = passwordRules(value);

        scope.numberError = !validProperties.numberCheck;
        scope.lengthError = !validProperties.lengthCheck;
        scope.upperCharError = !validProperties.upper;
        scope.lowerCharError = !validProperties.lower;

        ngModel.$setValidity(elementName, validProperties.allValid);

        return value;
      }

      // called on model changes
      ngModel.$parsers.unshift(passwordChecks);

      // called on page load
      ngModel.$formatters.unshift(passwordChecks);
    }
  };
}
