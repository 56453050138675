/* eslint-disable no-underscore-dangle */
import angular from 'angular';
import ngTranslate from 'angular-translate';
import 'angular-translate-loader-static-files';

// import here to get it added first
import './app.less';

import passwordCheck from './directives/passwordCheck';
import phLogin from './directives/phLogin';
import passwordReset from './directives/passwordReset';
import LoginServiceFactory from './factories/Login';

import configuration from './lib/configuration';
import { appUrlForUser } from './lib/domains';

import {
  allowedLanguages,
  determinePreferredLanguage,
  fallbacksFor,
  SUBSTITUTIONS
} from './lib/locales';

angular
  .module('signInApp', [ngTranslate])
  .config([
    '$locationProvider',
    '$translateProvider',
    '$windowProvider',
    function ($locationProvider, $translateProvider, $windowProvider) {
      $locationProvider.html5Mode(true);

      const $window = $windowProvider.$get();

      const preferredLanguage = determinePreferredLanguage($window);

      $translateProvider
        .useLoaderCache(true)
        .useStaticFilesLoader({
          prefix: 'locales/',
          suffix: '.json'
        })
        .useSanitizeValueStrategy(null)
        .registerAvailableLanguageKeys(allowedLanguages, SUBSTITUTIONS)
        .fallbackLanguage(fallbacksFor(preferredLanguage))
        .preferredLanguage(preferredLanguage)
        .use(preferredLanguage);

      if ($window.__serverVars) {
        configuration.setAll($window.__serverVars);
      }
    }
  ])
  .factory('LoginService', LoginServiceFactory)
  .factory('MissingTranslationHandler', ['$q', '$log', function ($q, $log) {
    return function (/* part, lang, response */) {
      // $log.error('The "' + part + '/' + lang + '" part was not loaded.');
      // $log.error(response);
      return $q.when({});
    };
  }])
  .directive('passwordCheck', passwordCheck)
  .directive('passwordReset', passwordReset)
  .directive('phLogin', phLogin)
  .run([
    '$window',
    '$rootScope',
    'LoginService',
    function ($window, scope, LoginService) {
      scope.resetPassword = LoginService.getAppState() > 0;

      if (!scope.resetPassword) {
        LoginService.signIn().then((auth) => {
          // this shouldn't really happen, but if it does we're on the way to keycloak already
          if (!auth) return;

          $window.location.href = appUrlForUser(auth);
        });
      }
    }
  ]);
